<template>
  <v-col sm="6" md="4" lg="2">
    <v-menu
      v-model="menuModal"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="100px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          name="date-search"
          :label="label"
          v-model="itemValue"
          readonly
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            slot="append"
            :title="$t('removeFilter')"
            @click="$emit('remove')"
          >
            mdi-close
          </v-icon>
        </v-text-field>
      </template>
      <v-date-picker
        no-title
        v-model="itemValue"
        @input="
          menuModal = false;
          handleInput();
        "
        date-picker
      ></v-date-picker>
    </v-menu>
  </v-col>
</template>

<script>
export default {
  name: "DateSearch",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {},
  },
  data: function () {
    return {
      menuModal: false,
      itemValue: this.value,
    };
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
  },
};
</script>

<style scoped>
.v-text-field {
  margin-top: 0;
}
</style>
